
import { Options, Vue } from 'vue-class-component';
import { OrderClient } from '@/services/Services';
import CartItem from '@/components/cartItem.vue';
import * as OM from '@/model';

@Options({
    components: {
        CartItem
    }
})
export default class OrderDetail extends Vue {

    order: OM.OrderVM = new OM.OrderVM();
    
    created(){
        var orderId = this.$route.params.identifier.toString();
        OrderClient.getOrderDetail(orderId)
        .then(x => {
            this.order = x;
        })
    }

    get hasShippableItem(){
        return this.order.cartItems.find( x => x.isShippable) != null
    }
}

